@import url('../theme.css');
@import url('../App.css');

.test {
    min-height: 500px;
    width: 100%;
}

.productContainer {
    height: auto;
}

.productsContactButton {
    background-color: var(--primary);
    border: 1px var(--primary);
}

.cardContainer {
    min-height: 375px;
}

.productSection {
    width: 100%;
    color: white;
    height: 600px;
    background-image: url('../../../public/images/pexels-riccardo-zerbinati-9638474.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative; /* Add position relative to create a new stacking context */
}

.productSection::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Adjust the opacity as needed */
}

.productSection .row {
    position: relative; /* Add position relative to create a new stacking context for the content */
    z-index: 1; /* Ensure content appears above the overlay */
}

.productImageContainer {
    max-height: 250px;
    height: 250px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    overflow-x: hidden;
    overflow-y: hidden;

    background-color: #fefffe;
    border-radius: 10px;
}

.productImage {
    width: auto;
    padding: 10px;
    max-width: 50%;
    object-fit: cover;
    object-position: center;
}

.cardBodyTitleContainer {
    display: flex;
    justify-content: center;
    max-height: 25px;
    height: 25px;
}

.vertical-ruler {
    border-left: 1px solid #ccc;
    height: 100%;
    margin: 0 20px;
}

.productButtonContainer {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    /* margin-top: 40px; */
}
.productItemsContainer {
    position: relative;
    z-index: 99;
    padding-bottom: 100px;
}