.contactContainer {
    min-height: 500px;
    padding-top: 100px;
    padding-bottom: 100px;
}
.mapContainer {
    width: 100%;
    border-radius: 10px;
}
.contactPageInfo h4 {
    width: 250px;
}
.contactPageInfo span {
    color: var(--primary);
}