.admin-logged-container {
    /* background-color: red; */
    padding: 10px;
}
.admin-logged-content {
    width: 100%;

    display: flex;
    justify-content: center;
}
.table-container {
    border-radius: 10px;
    border: 3px solid white;
}