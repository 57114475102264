.loadingContainer {
    height: 100vh;
    width: 100vw;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 5s ease-in-out;
}

.loadingContentContainer {
    height: 100px;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: space-between;
    
}

.loaded {
    opacity: 0;
    pointer-events: none;
}