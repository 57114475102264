:root {
    --primary: #B90504;
    --secondary: #990100;
    --primary-background: #F6F6F6;
    --secondary-background: #E8E8E8;
    --dark-background: #333333;
}

.text-custom-primary {
    color: var(--primary);
}