@import url('../theme.css');
@import url('../App.css');


.whatWeDoContainer {
    color: white;
    z-index: 100;
    position: relative;

    background-color: var(--dark-background);
}
.whatWeDoCard {
    z-index: 99;
}
.slanted-divider-home {
    position: relative;
    bottom: -100px;
    left: 0;
    width: 100%;
    height: 200px;
    background-color: var(--dark-background);
    transform: skewY(3deg);
    /* z-index: 99; */
}
.slanted-divider-home2 {
    position: relative;
    bottom: 100px;
    left: 0;
    width: 100%;
    height: 200px;
    background-color: var(--dark-background);
    transform: skewY(-3deg);
    z-index: 0;
}