@import url('../App.css');

.contactUsContainer {
    min-height: 500px;
    width: 100vw;
}

.contactUsSection {
    width: 100%;
    color: white;
    height: 500px;
    background-image: url('../../../public/images/pexels-fox-1595385.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative; /* Add position relative to create a new stacking context */
}
.contactUsSection::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Adjust the opacity as needed */
}
.contactUsSection .row {
    position: relative; /* Add position relative to create a new stacking context for the content */
    z-index: 1; /* Ensure content appears above the overlay */
}

.contactUsContentContainer {
    position: relative;
    z-index: 99;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}