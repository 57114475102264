@import url('./theme.css');

@font-face {
    font-family: 'SohoGothicPro-Bold';
    src: url('../../fonts/SohoGothicPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'SohoGothicPro-Light';
    src: url('../../fonts/SohoGothicPro-Light.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'CircularSpotifyTxT-Book';
    src: url('../../fonts/CircularSpotifyTxT-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
    background-color: var(--dark-background);
    font-family: 'SohoGothicPro-Bold', sans-serif;
}

.App {
    max-width: 100vw;
    background-color: var(--dark-background);
}

.contentContainer {
    min-height: calc(100vh - 500px);
    background-color: var(--primary-background);

    padding-bottom: 230px;
}

.btn-primary-hs {
    background-color: var(--primary);
}
.text-font-light {
    font-family: 'SohoGothicPro-Light', sans-serif;
}