@import url('./theme.css');

/* .hero-section {
    background-image: url('../../public/images/hero.jpg');
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: center;
    padding: 300px 0;
    border-radius: 0;
}

.dark-filter {
    filter: brightness(70%) contrast(130%);
} */

.hero-section {
    position: relative;
    overflow: hidden;
    border-radius: 0;
    border: none;
}

.hero-background {
    background-image: url('../../public/images/hero.jpg');
    /* Additional styling */
    background-size: cover;
    background-position: center;
    color: #fff;
    border-bottom: solid 3px var(--primary-background);
    text-align: center;
    padding: 300px 0;
    position: relative;
}

.hero-background::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    /* Adjust the opacity as needed */
}

.hero-content {
    position: relative;
    z-index: 1;

    width: 80%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero-content h1 {
    text-align: start;
    text-wrap: wrap;
    width: 400px;

    font-weight: bold;
    /* -webkit-text-stroke: 0.5px black; */
    font-size: 50px;
    font-family: 'SohoGothicPro-Bold', sans-serif;

    margin-bottom: 50px;
}

.slanted-divider {
    position: absolute;
    bottom: -80px;
    left: 0;
    width: 100%;
    height: 200px;
    background-color: var(--primary-background);
    transform: skewY(-3deg);
    z-index: 99;
}

.heroInput {
    display: flex;
    width: 80%;

    justify-content: space-between;
    align-items: flex-start;
}

.emailInputContainer {
    width: 100%;
}
.emailInputHero {
    font-family: 'SohoGothicPro-Bold', sans-serif;
    width: 100%;
    margin-right: 10px;
    flex-grow: 2;
}

.heroInput Button {
    padding: 14px;
    flex-grow: 1;
    font-family: 'SohoGothicPro-Bold', sans-serif;
    background-color: var(--primary);
    border: var(--primary);
}

/* Mobile */

.hero-mobile {
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.hero-mobile h1 {
    text-align: start;
    text-wrap: wrap;
    width: 300px;

    font-weight: bold;
    -webkit-text-stroke: 0.5px black;
    font-size: 40px;
    font-family: 'SohoGothicPro-Bold', sans-serif;

    margin-bottom: 50px;
}

.hero-mobile .heroInput {
    display: flex;
    flex-direction: column;
    width: 100%;

    justify-content: space-between;
    align-items: flex-start;
}

.hero-mobile .emailInputHero {
    font-family: 'SohoGothicPro-Bold', sans-serif;
    width: 100%;
    margin-right: 10px;
    flex-grow: 2;
}

.hero-mobile .heroInput Button {
    width: 100%;
    font-family: 'SohoGothicPro-Bold', sans-serif;
    background-color: var(--primary);
    border: var(--primary);
}

.heroMobileBodyContent {
    position: absolute;
    top: 100px;
    width: 100%;
}