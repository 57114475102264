@import url('./theme.css');

.slanted-dividers {
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: 150px;
    background-color: var(--dark-background);
    transform: skewY(12deg);
    z-index: 1;
}

.slanted-dividers2 {
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: 150px;
    background-color: var(--dark-background);
    transform: skewY(-12deg);
    z-index: 1;
}

.desktop-divider {
    height: 450px;
    overflow-y: hidden;
    transform: skewY(-6deg);
}

.desktop-divider2 {
    height: 450px;
    overflow-y: hidden;
    transform: skewY(6deg);
}


.seperator-style-footer {
    position: relative;
    color: red;
    width: 80%;
    margin-left: auto;
    margin-right: auto;

    z-index: 99;
}

.footerContainer {
    height: 500px;
    width: 100vw;
    position: relative;
    color: white;
    font-size: 14px;
    font-family: 'CircularSpotifyTxT-Book', sans-serif;
    background-color: var(--dark-background);
}

.footerContentContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: 9;
}

.footerContentTop {
    flex: 1;
}

.footerSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 50px;
    padding-bottom: 25px;
    min-height: 250px;
    border-right: 0.5px solid var(--primary);
    font-family: 'CircularSpotifyTxT-Book', sans-serif;
}

.footerSectionMobile {
    padding-left: 25px;
    border-right: 0.5px solid var(--primary);
    font-family: 'CircularSpotifyTxT-Book', sans-serif;
}

.footerSectionMobile ul {
    margin-top: 20px;
    font-family: 'CircularSpotifyTxT-Book', sans-serif;
    text-decoration: none;
}
.test {
    font-size: 1000px;
}

.footerSection ul {
    margin-top: 20px;
    font-size: 1.2rem;
    font-family: 'CircularSpotifyTxT-Book', sans-serif;
    text-decoration: none;
}

.footerContentBottom {
    min-height: 100px;
    margin-bottom: 60px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--dark-background);

    z-index: 999;
}

.footerContentBottom p {
    text-align: center;
    width: 180px;
}

.footerInputSection {
    width: 100%;
    margin-top: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.footerInputSection Button {
    background-color: var(--primary);
    border: var(--primary);

    padding: 20px;
    text-align: center;
}