@import url('./theme.css');

/*

.navbar-container {
    background-color: var(--secondary-background);
}

@keyframes spin {
    0% { 
        transform: rotate(0deg); 
    }
    100% { 
        transform: rotate(45deg);
     }
}

@keyframes spinBack {
    100% { transform: rotate(0deg); }
    0% { transform: rotate(90deg); }
}
.moon {
    animation: spinBack 0.3s linear;
}
.sun {
    animation: spin 0.2s linear;
}

@keyframes expand {
    0% {
        opacity: 0.5; 
    }
    100% {
        height: 100vh;
        opacity: 1;
    }
}
.navbarAnim {
    animation: expand 1s forwards;
} */

/* .mobile-nav-container {
    position: relative;
} */
.seperator-style {
    color: var(--dark-background);
}
.menu-toggle-btn {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 50px;
    background-color: var(--primary);
    border: none;
    border-radius: 50%;
    color: white;
    font-size: 24px;
    cursor: pointer;
    z-index: 1000000000;
}

.desktopNavToggle {
    color: white;
    background-color: var(--primary);
    width: 50px;
    height: 50px;
    border: none;

    border-radius: 10px;
}

.icon {
    color: var(--dark-background);
}

.logo-class {
    height: 50px;

}
.navigation-container {
    display: flex;
    flex-grow: 1;
    color: black !important;
    text-decoration: none;
    flex-direction: column;
}
/* .navbar-container {
    background-color: var(--secondary-background);
    transform: translateX(-100%);
    transition: transform 0.5s ease-in-out;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 90%;
    z-index: 999;
}

.menu-open {
    transform: translateX(0);
    transition: transform 0.5s ease-out;
} */

/* .menu-closed {
    transform: translateX(-100);
    transition: transform 0.5s ease-out;
} */